<template>
  <div>
    <div class="p-grid crud-demo">
      <div class="p-col-12">
        <div class="card">
          <DataTable
            ref="dt"
            :value="listData"
            data-key="id"
            :paginator="true"
            :rows="10"
            :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            currentPageReportTemplate="{totalRecords} utilisateurs"
            responsiveLayout="scroll"
          >
            <template #header>
              <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-start p-ai-md-center">
                <h5 class="p-mb-2 p-m-md-0">Gestion des utilisateurs internes</h5>
                <Button @click="eventAddUser" label="Ajouter un utilisateur" class="p-mr-2 p-mb-2" />
              </div>
            </template>

            <Column field="firstName" header="NOM" :sortable="true" bodyStyle="text-indent:1em">
              <template #body="slotProps">{{ slotProps.data.lastName }} {{ slotProps.data.firstName }}</template>
            </Column>
            <Column field="email" header="EMAIL" :sortable="true" bodyStyle="text-indent:1em">
              <template #body="slotProps">{{ slotProps.data.email }}</template>
            </Column>
            <Column field="roles" header="ROLE" :sortable="false" bodyStyle="text-indent:1em">
              <template #body="slotProps">
                <!--{{ slotProps.data.roles.find(role.name === 'VAL_PUBLICATION') && 'Validateur de publication' }}
                <br />{{ slotProps.data.roles.find(role.name === 'VAL_PAIEMENT') && 'Validateur de paiement' }}
                 <br />{{ slotProps.data.roles.find(role.name === 'VAL_INSCRIPTION') && "Validateur d'inscription" }}-->
                <ul style="list-style: none; padding: 0">
                  <li>
                    {{
                      slotProps.data.roles.find((role) => role.name === 'VAL_PUBLICATION') &&
                      'Validateur de publications'
                    }}
                  </li>
                  <li>
                    {{ slotProps.data.roles.find((role) => role.name === 'VAL_PAIEMENT') && 'Validateur de paiement' }}
                  </li>
                  <li>
                    {{
                      slotProps.data.roles.find((role) => role.name === 'VAL_INSCRIPTION') && "Validateur d'inscription"
                    }}
                  </li>
                </ul>
              </template>
            </Column>
            <Column field="action" header="Actions" :sortable="false" bodyStyle="text-indent:1em">
              <template #body="slotProps">
                <span class="p-column-title">Actions</span>
                <Button
                  icon="pi pi-pencil"
                  class="p-button-outlined p-button-rounded p-button-info p-mr-2"
                  @click="edit(slotProps.data)"
                />
                <Button
                  icon="pi pi-trash"
                  class="p-button-outlined p-button-rounded p-button-danger"
                  @click="erase(slotProps.data)"
                />
              </template>
            </Column>
          </DataTable>

          <Dialog
            v-model:visible="updateDialog"
            :style="{ width: '450px' }"
            header="Fiche utilisateur interne"
            :modal="true"
            class="p-fluid"
          >
            <div class="p-field">
              <label for="name">Nom</label>
              <span class="p-text-bold text-red">*</span>
              <InputText
                id="name"
                v-model.trim="user.lastName"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !user.firstName }"
              />
              <small class="p-invalid" v-if="submitted && !product.name">Name is required.</small>
            </div>
            <div class="p-field">
              <label for="name">Prénom</label>
              <span class="p-text-bold text-red">*</span>
              <InputText
                id="name"
                v-model.trim="user.firstName"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !user.firstName }"
              />
              <small class="p-invalid" v-if="submitted && !product.name">Name is required.</small>
            </div>
            <div class="p-field">
              <label for="description">E-mail</label>
              <span class="p-text-bold text-red">*</span>
              <InputText
                id="name"
                v-model.trim="user.email"
                required="true"
                :disabled="true"
                :class="{ 'p-invalid': submitted && !user.firstName }"
              />
            </div>
            <div class="p-field">
              <label for="roles-id">Role</label>
              <span class="p-text-bold text-red">*</span>
              <MultiSelect
                id="roles-id"
                :filter="true"
                v-model="role_update"
                :options="dropdownValues"
                optionValue="id"
                optionLabel="name"
                placeholder="Choisir"
                :allow-empty="required"
              />
            </div>
            <template #footer>
              <Button
                label="Annuler"
                icon="pi pi-times"
                class="p-button p-component p-button-outlined p-button-danger"
                @click="hideDialog"
              />
              <Button
                label="Enregistrer"
                icon="pi pi-check"
                class="p-button p-component p-button-outlined p-button-success"
                @click="update"
              />
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteDialog" :style="{ width: '600px' }" header :modal="true" class="p-fluid">
            <h5 style="text-align: center">Voulez-vous vraiment supprimer cet utilisateur ?</h5>

            <template #footer>
              <Button
                label="NON"
                icon="pi pi-delete"
                class="p-button p-component p-button-outlined p-button-danger"
                @click="hideDialog"
              />
              <Button
                label="OUI"
                icon="pi pi-delete"
                class="p-button p-component p-button-outlined p-button-success"
                @click="deleteUser(user.id)"
              />
            </template>
          </Dialog>

          <Dialog
            v-model:visible="addingUser"
            :style="{ width: '450px' }"
            header="Fiche utilisateur interne"
            :modal="true"
            class="p-fluid"
          >
            <div class="p-field">
              <label for="name">Nom</label>
              <span class="p-text-bold text-red">*</span>
              <InputText
                id="name"
                v-model.trim="lastName"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !user.firstName }"
              />
              <small class="p-invalid" v-if="submitted && !product.name">Name is required.</small>
            </div>
            <div class="p-field">
              <label for="name">Prénom</label>
              <span class="p-text-bold text-red">*</span>
              <InputText
                id="name"
                v-model.trim="firstName"
                required="true"
                :class="{ 'p-invalid': submitted && !user.firstName }"
              />
              <small class="p-invalid" v-if="submitted && !product.name">Name is required.</small>
            </div>
            <div class="p-field">
              <label for="name">E-mail</label>
              <span class="p-text-bold text-red">*</span>
              <InputText
                id="name"
                v-model.trim="email"
                required="true"
                :class="{
                  'p-invalid': (submitted && !user.firstName) || isErrorCreation,
                }"
              />
              <small class="p-invalid" v-if="submitted && !product.name">Name is required.</small>
            </div>
            <div class="p-field">
              <label for="roles-id">Role</label>
              <span class="p-text-bold text-red">*</span>
              <MultiSelect
                id="roles-id"
                :filter="true"
                v-model="role_update"
                :options="dropdownValues"
                optionValue="id"
                optionLabel="name"
                placeholder="Choisir"
                :allow-empty="required"
              />
            </div>
            <template #footer>
              <Button
                label="Annuler"
                icon="pi pi-times"
                class="p-button p-component p-button-outlined p-button-danger"
                @click="hideDialog"
              />
              <Button
                label="Enregistrer"
                icon="pi pi-check"
                class="p-button p-component p-button-outlined p-button-success"
                @click="addAdmin"
              />
              <div
                v-bind:style="{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }"
              >
                <!--<Button label="Enregistrer en tant que validateur d'inscription" class="p-button-p-button-outlined p-button-rounded p-mr-2 p-mb-2" :style="{ width: '317.5px' }" @click="addControllerSUBS" />
                <Button label="Enregistrer en tant validateur de publication" class="p-button-p-button-outlined p-button-rounded p-mr-2 p-mb-2" @click="addControllerPPM" :style="{ width: '317.5px' }" />
                <Button label="Enregistrer en tant validateur de paiement" class="p-button-p-button-outlined p-button-rounded p-mr-2 p-mb-2" @click="addControllerPaiement" :style="{ width: '317.5px' }" />-->
              </div>
            </template>
            <h6 v-if="isErrorCreation" style="color: red">Utilisateur déja existant</h6>
          </Dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../services/http.service';

export default {
  data() {
    return {
      tarifications: [],
      listData: [],
      dropdownValues: [
        { id: 'ROLE_ADMIN', name: 'Super Administrateur' },
        { id: 'ROLE_SIMPLE_ADMIN', name: 'Administrateur Simple' },
        { id: 'VAL_PAIEMENT', name: 'Validateur de paiement' },
        { id: 'VAL_PUBLICATION', name: 'Validateur de publication' },
        { id: 'VAL_INSCRIPTION', name: 'Validateur inscription' },
      ],
      config: null,
      updateDialog: false,
      deleteDialog: false,
      addingUser: false,
      lastName: '',
      firstName: '',
      email: '',
      role: '',
      role_update: [],
      idsup: null,
      isErrorCreation: false,
    };
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.fetchUsers();
      this.getTarifications();
      //this.addRole();
    } else {
      this.$router.push('/login');
    }
  },

  methods: {
    async fetchUsers() {
      const token = localStorage.getItem('token');
      const URL = '/users?limit=5000&sort=lastName&sort=asc';
      this.config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      await http({
        method: 'get',
        url: URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const data = res.data.data;
          this.listData = data.filter(
            (u) => u.roles.find((role) => role.authorities.includes('VALIDATEUR')) !== undefined
          );
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log('ERROR IN FETCH USERS =>' + err);
        });
    },
    async getTarifications() {
      await http.get('/tarifs?limit=100?sort=montant_xof,asc', this.config).then((res) => {
        this.tarifications = res.data;
        this.tarifications.sort((a, b) => b.montant_xof - a.montant_xof);
      });
    },

    edit(user) {
      this.user = { ...user };
      this.role_update = this.user.roles.map((role) => role.name);
      this.updateDialog = true;
    },
    erase(user) {
      this.user = { ...user };
      this.deleteDialog = true;
    },
    eventAddUser() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.isErrorCreation = false;
      this.addingUser = true;
    },
    async update() {
      const token = localStorage.getItem('token');
      try {
        console.log('ROLE => ' + this.role_update);
        await http.put(
          '/users/' + this.user.id + '/',
          {
            email: this.user.email,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            password: 'passwd',
            mobile: null,
            phone: null,
            positionDescription: 'controller',
            enabled: true,
            locked: false,
            roleList: this.role_update,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        this.updateDialog = false;
        this.fetchUsers();
      } catch (e) {
        console.log('error when updating => ' + e);
      }
    },
    async deleteUser() {
      const token = localStorage.getItem('token');
      try {
        await http.delete('/users/' + this.user.id + '/', {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.deleteDialog = false;
        this.fetchUsers();
      } catch (e) {
        console.log('error when updating => ' + e);
      }
    },

    async addAdmin() {
      const token = localStorage.getItem('token');
      if (this.firstName.length >= 1 && this.lastName.length >= 1 && this.email.indexOf('@') !== -1) {
        var data = {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          positionDescription: 'Controlleur',
          enabled: true,
          locked: false,
          roleList: this.role_update,
        };

        http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        await http
          .post('/users', data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(
            () => {
              this.addingUser = false;
              this.fetchUsers();
            },
            () => {
              this.isError = true;
              this.isErrorCreation = true;
            }
          );
      }
    },

    async addControllerSUBS() {
      const token = localStorage.getItem('token');
      if (this.firstName.length >= 1 && this.lastName.length >= 1 && this.email.indexOf('@') !== -1) {
        var data = {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          password: 'pass123',
          mobile: null,
          phone: null,
          positionDescription: 'controller',
          enabled: true,
          locked: false,
          role: 'VAL_INSCRIPTION',
        };

        http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        await http
          .post('/users', data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(
            (res) => {
              this.addingUser = false;
              this.fetchUsers();
              console.log('***USER ADDED WITH SUCCESS*** => ' + res.data);
            },
            (error) => {
              this.isError = true;
              this.isErrorCreation = true;
              console.log('ERROR ON ADDING-> ' + error);
            }
          );
      }
    },
    async addControllerPPM() {
      const token = localStorage.getItem('token');
      if (this.firstName.length >= 1 && this.lastName.length >= 1 && this.email.indexOf('@') !== -1) {
        var data = {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          password: 'pass123',
          mobile: null,
          phone: null,
          positionDescription: 'controller',
          enabled: true,
          locked: false,
          role: 'VAL_PUBLICATION',
        };

        await http
          .post('/users', data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(
            (res) => {
              this.addingUser = false;
              this.fetchUsers();
              console.log('***USER ADDED WITH SUCCESS*** => ' + res.data);
            },
            (error) => {
              this.isErrorCreation = true;
              console.log('ERROR ON REGISTER-> ' + error);
            }
          );
      }
    },
    async addControllerPaiement() {
      const token = localStorage.getItem('token');
      if (this.firstName.length >= 1 && this.lastName.length >= 1 && this.email.indexOf('@') !== -1) {
        var data = {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          password: 'pass123',
          mobile: null,
          phone: null,
          positionDescription: 'controller',
          enabled: true,
          locked: false,
          role: 'VAL_PAIEMENT',
        };

        await http
          .post('/users', data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(
            (res) => {
              this.addingUser = false;
              this.fetchUsers();
              console.log('***USER ADDED WITH SUCCESS*** => ' + res.data);
            },
            (error) => {
              this.isErrorCreation = true;
              console.log('ERROR ON CREATION-> ' + error);
            }
          );
      }
    },
    hideDialog() {
      this.addingUser = false;
      this.updateDialog = false;
      this.deleteDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
.text-red {
  color: #db3c30;
}
</style>
